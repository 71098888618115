import { FunctionComponent, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import FormElement from "../../components/FormElement/formElement";
import Button from "../../components/Button/button";
import UserActions from "../../api/actions/user.actions";
import Eye from "../../components/Eye/eye";

import styles from "./register.module.css";
import Container from "../../components/Container/container";

interface RegisterProps {

    setToken: React.Dispatch<React.SetStateAction<string | null>>
}

type RegisterFormValues = {
    company: string
    email: string
    name: string
    password: string
    phone: string
}

const Register: FunctionComponent<RegisterProps> = ({ setToken }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false)
    const [registerErrors, setRegisterErrors] = useState<null | string>(null)
    const [registerValidationErrors, setRegisterValidationErrors] = useState(new Map())

    const navigate = useNavigate()

    const submitHandler = async (e: any) => {
        e.preventDefault()

        setRegisterErrors(null)
        setRegisterValidationErrors(new Map())
        setLoading(true)

        let formValues = Object.fromEntries(new FormData(e.target as HTMLFormElement)) as RegisterFormValues

        const { success, message, validationErrors, token } = await UserActions.register(formValues)


        if (!success) {
            if (validationErrors) {
                setRegisterValidationErrors(() => {
                    let errors = new Map()
                    validationErrors.forEach((err: any) => {
                        let errorMessage = Object.values(err.constraints)[0];
                        errors.set(err.property, errorMessage)

                    });

                    return errors;
                })

            }

            setRegisterErrors(message);
            setLoading(false)
        } else {

            setToken(token);
            navigate("/")
        }
    }


    return (
        <div className={styles.register}>
            <h1>Register</h1>

            <Container>
                <form onSubmit={submitHandler} autoComplete="on" >
                    <fieldset disabled={loading}>
                        <FormElement text="Email" htmlFor="email" errorMessage={registerValidationErrors.get("email")}>
                            <input required name="email" type="email" id="email" autoComplete="email" />
                        </FormElement>

                        <FormElement text="Name" htmlFor="name" errorMessage={registerValidationErrors.get("name")}>
                            <input required name="name" type="text" id="name" autoComplete="name" />
                        </FormElement>

                        <FormElement text="Company" htmlFor="company" errorMessage={registerValidationErrors.get("company")}>
                            <select name="company">
                                <option value={"Salto Systems"}>Salto Systems</option>
                                <option value={"Conlan ApS"}>Conlan ApS</option>
                                <option value={"Telesikring"}>Telesikring</option>
                            </select>
                            {/* <input required name="company" type="text" id="company" autoComplete="organization" /> */}
                        </FormElement>

                        <FormElement text="Phone" htmlFor="phone" errorMessage={registerValidationErrors.get("phone")}>
                            <input required name="phone" type="tel" id="phone" autoComplete="tel" />
                        </FormElement>

                        <FormElement text="Password" htmlFor="password" errorMessage={registerValidationErrors.get("password")}>
                            <input required name="password" type={showPassword ? "text" : "password"} id="password" autoComplete="new-password" minLength={6} />
                            <Eye open={showPassword} onClick={() => setShowPassword(prev => !prev)} />
                        </FormElement>

                        <FormElement>
                            <Button type="submit" block loading={loading}>Register</Button>
                        </FormElement>

                    </fieldset>

                    {registerErrors &&
                        <Container isError>
                            <h3>{registerErrors}</h3>
                        </Container>
                    }
                </form>

            </Container>

            <p className={styles.loginLink}>Already have an account? <Link to={"/login"}>Login</Link></p>
        </div>
    );
}

export default Register;