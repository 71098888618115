import KeypadService from "../services/keypad.service";

interface DefaultRequest {
    success: boolean
    message: string
    statusCode: number

}


interface RegisterKeypadRequest extends DefaultRequest {
    validationErrors?: Object[]
}

// interface GenerateCodeRequest extends DefaultRequest {
//     code: string,
//     address: string
// }

export interface RegisterBody {
    userSeed: string
    keypadCustomName: string
    keypadServiceConfCode: string
    keypadUserConfCode: string
    location: {
        latitude: string
        longitude: string
    }
}

export interface EditKeypadBody {
    keypadCustomName: string
    userSeed: string
    keypadServiceConfCode: string
    keypadUserConfCode: string,
    keypadComment: string
}

export default class KeypadActions {

    static async register(keypadInfo: RegisterBody): Promise<RegisterKeypadRequest> {
        return await KeypadService.register(keypadInfo)
    }

    static async getInstalledKeypads() {
        return await KeypadService.getInstalledKeypads()
    }

    static async updateKeypad(keypadId: string, keypadInfo: EditKeypadBody) {
        return await KeypadService.updateKeypad(keypadId, keypadInfo)
    }
}
