import { FunctionComponent, useState } from "react";
import Container from "../../components/Container/container";
import FormElement from "../../components/FormElement/formElement";
import Button from "../../components/Button/button";
import UserActions from "../../api/actions/user.actions";

interface ForgotPasswordProps {

}

type ForgotPasswordFormValues = {
    email: string
}

const ForgotPassword: FunctionComponent<ForgotPasswordProps> = (props) => {
    const [loading, setLoading] = useState(false)
    const [forgotPasswordErrors, setForgotPasswordErrors] = useState<string>()
    const [forgotPasswordValidationErrors, setForgotPasswordValidationErrors] = useState(new Map())
    const [forgotPasswordSuccessMessage, setForgotPasswordSuccessMessage] = useState<string>()




    async function submitHandler(e: any) {
        e.preventDefault()

        setLoading(true);
        setForgotPasswordValidationErrors(new Map())
        setForgotPasswordErrors(undefined)

        const data = Object.fromEntries(new FormData(e.target).entries()) as ForgotPasswordFormValues;

        const { success, message, validationErrors } = await UserActions.forgotPassword(data.email)

        if (!success) {
            if (validationErrors) {
                setForgotPasswordValidationErrors(() => {
                    let errors = new Map()
                    validationErrors.forEach((err: any) => {
                        let errorMessage = Object.values(err.constraints)[0];
                        errors.set(err.property, errorMessage)

                    });

                    return errors;
                })

            }
            setForgotPasswordErrors(message)

        } else {
            setForgotPasswordSuccessMessage(message)
        }

        setLoading(false);
    }





    return (
        <div >
            <h1>Forgot password</h1>
            <Container>
                <form onSubmit={submitHandler}>
                    <fieldset>
                        <FormElement text="Email" htmlFor="email" errorMessage={forgotPasswordValidationErrors.get("email")} >
                            <input name="email" type="email" />
                        </FormElement>

                        <FormElement>
                            <Button type="submit" loading={loading} block>Send email</Button>
                        </FormElement>
                    </fieldset>
                </form>
                {forgotPasswordSuccessMessage &&
                    <Container isSuccess>
                        <h3>{forgotPasswordSuccessMessage}</h3>
                    </Container>
                }
            </Container>
        </div>
    );
}

export default ForgotPassword;