import { FunctionComponent, ReactNode } from "react";

import styles from "./drawer.module.css"
import { CloseOutlined } from "@ant-design/icons";

interface DrawerProps {
    isOpen: boolean
    close: () => void
    title?: string
    showCloseButton?: boolean
    children: ReactNode
}

const Drawer: FunctionComponent<DrawerProps> = ({ isOpen, close, title, showCloseButton, children }) => {
    return (
        <div className={[styles.backdrop, isOpen ? styles.open : ""].join(" ")} onClick={() => close()}>
            <div className={styles.drawer} onClick={(e) => { e.stopPropagation() }}>
                {(title || showCloseButton) &&
                    <div className={styles.header}>
                        {title
                            ? <h3>{title}</h3>
                            : <h3>&#8203;</h3>
                        }

                        {showCloseButton &&
                            <CloseOutlined className={styles.closeButton} onClick={close}/>
                        }
                    </div>
                }
                {children}
            </div>
        </div>
    );
}

export default Drawer;