
export async function handleResponse(response: Response) {

    return response.text().then(text => {
        let data;
        
        try {
            data = {...JSON.parse(text), statusCode: response.status};
        } catch (error) {
            data = { success: false, message: "Unexpected error" }
        }

        return data;
    });
}