import { ButtonHTMLAttributes, FunctionComponent, ReactNode } from "react";

import styles from "./button.module.css";
import { LoadingOutlined } from "@ant-design/icons";

interface ButtonProps {
    children: ReactNode
    loading?: boolean
    block?: boolean
    buttonType?: "primary" | "secondary",
    className?: string
}

const Button: FunctionComponent<ButtonProps & ButtonHTMLAttributes<{}>> = ({ children, loading, block = false, buttonType = "primary", className, ...props }) => {
    return (
        <button className={
            [
                styles.button,
                block? styles.block : "",
                styles[buttonType],
                className
            ].join(" ")} {...props} disabled={loading || props.disabled}>
            <span className={[styles.loadContainer, loading ? styles.loading : ""].join(" ")}>
                <LoadingOutlined />
            </span>
            {children}
        </button>
    );
}

export default Button;