import { handleResponse } from "../helpers/responseHandler";

interface GenerateBody {
    userSeed: string
    year: number
    month: number
    day: number
    hour: number
    typeOfCode: string
}

export default class CodeService {

    private static url = (
        process.env.NODE_ENV === "development"
            ? process.env.REACT_APP_API_URL_DEV
            : process.env.REACT_APP_API_URL_PRODUCTION
    ) + "/code";

    static async generate(body: GenerateBody) {
        const token = localStorage.getItem("token")

        const requestOptions: RequestInit = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + token },
            body: JSON.stringify(body)
        }

        return fetch(`${this.url}/generate`, requestOptions).then(handleResponse);
    }
}
