import { FunctionComponent, useEffect, useState } from "react";
import KeypadActions from "../../api/actions/keypad.actions";
import KeypadListItem from "../../components/KeypadListItem/keypadListItem";
import EditKeypad from "../EditKeypad/editKeypad";

import styles from "./keypadList.module.css"
import { LoadingOutlined } from "@ant-design/icons";
import Navigation from "../../components/Navigation/navigation";
import { useNavigate } from "react-router";

interface KetpadListProps {
    token?: any
}

const KeypadList: FunctionComponent<KetpadListProps> = ({ token }) => {
    const [keypads, setKeypads] = useState<any[]>()
    const [selectedKeypad, setSelectedKeypad] = useState<any>()
    const [closingEdit, setClosingEdit] = useState(false);
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        if (!token) navigate("/login")

        if (!keypads) getKeypads()
    }, []) // eslint-disable-line

    const getKeypads = async () => {
        setLoading(true)

        let response = await KeypadActions.getInstalledKeypads()

        let { installedKeypads } = response;
        if (installedKeypads) {
            setKeypads(installedKeypads)
        }

        setLoading(false)
    }

    const closeEdit = () => {
        setSelectedKeypad(undefined)
        setClosingEdit(true)
        setTimeout(() => {
            setClosingEdit(false)
        }, 500);
    }

    return (
        <div>
            <Navigation title="Keypads"/>
            <div className={styles.wrapper}>
                {loading
                    ? <LoadingOutlined style={{ fontSize: "40px", margin: "20px 0" }} />
                    : keypads && keypads?.length > 0
                        ? <div className={[styles.slider, selectedKeypad && styles.selected].join(" ")}>
                            <div>
                                {keypads?.map(keypad =>
                                    <KeypadListItem keypad={keypad} key={keypad.keypadId} setActiveKeypad={() => setSelectedKeypad(keypad)} />
                                )}
                            </div>

                            {(selectedKeypad || closingEdit) &&
                                <EditKeypad keypad={selectedKeypad} back={closeEdit} refreshKeypads={getKeypads} />
                            }
                        </div>
                        : <div>
                            <h3>No keypads found</h3>
                        </div>
                }
            </div>

        </div>
    );
}

export default KeypadList;