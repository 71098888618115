import { EditKeypadBody, RegisterBody } from "../actions/keypad.actions";
import { handleResponse } from "../helpers/responseHandler";

export default class KeypadService {

    private static url = (
        process.env.NODE_ENV === "development"
            ? process.env.REACT_APP_API_URL_DEV
            : process.env.REACT_APP_API_URL_PRODUCTION
    ) + "/keypad";

    static async register(body: RegisterBody) {
        const token = localStorage.getItem("token")

        const requestOptions: RequestInit = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + token },
            body: JSON.stringify(body)
        }

        return fetch(`${this.url}`, requestOptions).then(handleResponse);
    }

    static async getInstalledKeypads() {
        const token = localStorage.getItem("token")

        const requestOptions: RequestInit = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + token }
        }

        const response = await fetch(`${this.url}`, requestOptions);
        return handleResponse(response);
    }

    static async updateKeypad(keypadId:string, body:EditKeypadBody) {
        const token = localStorage.getItem("token")

        const requestOptions: RequestInit = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + token },
            body: JSON.stringify(body)
        }

        return fetch(`${this.url}/${keypadId}`, requestOptions).then(handleResponse);
    }

}
