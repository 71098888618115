import { LeftOutlined } from "@ant-design/icons";
import { FormEvent, FunctionComponent, useEffect, useRef, useState } from "react";
import Container from "../../components/Container/container";

import styles from "./editKeypad.module.css"
import FormElement from "../../components/FormElement/formElement";
import Button from "../../components/Button/button";
import KeypadActions from "../../api/actions/keypad.actions";
import Modal from "../../components/Modal/modal";

interface EditKeypadProps {
    keypad: any
    back: Function
    refreshKeypads: Function
}
type EditFormValues = {
    keypadCustomName: string
    userSeed: string
    keypadServiceConfCode: string
    keypadUserConfCode: string
    keypadComment: string
}

const EditKeypad: FunctionComponent<EditKeypadProps> = ({ keypad, back, refreshKeypads }) => {
    const [loading, setLoading] = useState(false)
    const [editKeypadValidationErrors, setEditKeypadValidationErrors] = useState(new Map())
    const [editKeypadErrors, setEditKeypadErrors] = useState<{ message: string, statusCode: number }>()
    const [editKeypadSuccessMessage, setEditKeypadSuccessMessage] = useState()
    const [editKeypad, setEditKeypad] = useState<any>()
    const [confirmUpdateModalOpen, setConfirmUpdateModalOpen] = useState(false)

    const formRef = useRef<HTMLFormElement>(null)

    useEffect(() => {
        if (keypad)
            setEditKeypad(JSON.parse(JSON.stringify(keypad || {})))
    }, [keypad])


    const confirmUpdate = () => {
        setConfirmUpdateModalOpen(true)
    }

    const closeConfirmUpdateModal = () => {
        setConfirmUpdateModalOpen(false)
    }

    const submitForm = () => {
        formRef.current?.requestSubmit();
        closeConfirmUpdateModal()
    }

    const updateKeypad = async (e: FormEvent<HTMLFormElement>) => {
        console.log("submit", e);
        e.preventDefault()

        setLoading(true)
        setEditKeypadValidationErrors(new Map())
        setEditKeypadErrors(undefined)
        setEditKeypadSuccessMessage(undefined)

        let values = Object.fromEntries(new FormData(e.target as HTMLFormElement)) as EditFormValues

        const { success, validationErrors, message, statusCode } = await KeypadActions.updateKeypad(editKeypad.keypadId, values)


        if (!success) {
            if (validationErrors) {
                let errors = new Map()

                validationErrors.forEach((err: any) => {

                    let errorMessage = Object.values(err.constraints)[0];

                    errors.set(err.property, errorMessage)
                });

                setEditKeypadValidationErrors(errors)
            }
            if (message) {
                setEditKeypadErrors({ message, statusCode });
            }
        } else {

            setEditKeypadSuccessMessage(message)
            console.log("success, get keypads");
            refreshKeypads()
        }

        setLoading(false)
    }

    return (
        <div className={styles.editKeypad}>
            <div className={styles.backButton} onClick={() => back()}>
                <LeftOutlined /><p>Back</p>
            </div>
            <Container>
                <form className={styles.form} onSubmit={updateKeypad} ref={formRef}>
                    <fieldset disabled={loading}>
                        <FormElement text="Seed code" htmlFor="userSeed" errorMessage={editKeypadValidationErrors.get("userSeed")} >
                            <input inputMode="numeric" name="userSeed" id="userSeed" required minLength={8} maxLength={8} defaultValue={editKeypad?.keypadSeed} />
                        </FormElement>

                        <FormElement text="Locker name" htmlFor="keypadCustomName" errorMessage={editKeypadValidationErrors.get("keypadCustomName")}>
                            <input name="keypadCustomName" id="keypadCustomName" required defaultValue={editKeypad?.keypadCustomName} />
                        </FormElement>

                        <FormElement text="Service Config Code" htmlFor="keypadServiceConfCode" errorMessage={editKeypadValidationErrors.get("keypadServiceConfCode")}>
                            <input inputMode="numeric" name="keypadServiceConfCode" id="keypadServiceConfCode" required minLength={8} maxLength={8} defaultValue={editKeypad?.keypadServiceConfCode} />
                        </FormElement>

                        <FormElement text="User Config Code" htmlFor="keypadUserConfCode" errorMessage={editKeypadValidationErrors.get("keypadUserConfCode")}>
                            <input inputMode="numeric" name="keypadUserConfCode" id="keypadUserConfCode" required minLength={4} maxLength={8} defaultValue={editKeypad?.keypadUserConfCode} />
                        </FormElement>

                        <FormElement text="Comment" htmlFor="keypadComment" errorMessage={editKeypadValidationErrors.get("keypadComment")}>
                            <input name="keypadComment" id="keypadComment" required minLength={1} defaultValue={editKeypad?.keypadComment} />
                        </FormElement>

                        <FormElement>
                            <Button loading={loading} block type="button" onClick={confirmUpdate} >Update</Button>
                        </FormElement>
                    </fieldset>
                </form>
                {editKeypadErrors &&
                    <Container isError>
                        <h3>{editKeypadErrors.message}</h3>
                    </Container>}
                {editKeypadSuccessMessage &&
                    <Container isSuccess>
                        <h3>{editKeypadSuccessMessage}</h3>
                    </Container>}
            </Container>

            <Modal isOpen={confirmUpdateModalOpen} close={closeConfirmUpdateModal} header={
                <h2>Are you sure you want to update?</h2>
            }>
                <div className={styles.updateConfirmButtons}>
                    <Button buttonType="secondary" onClick={closeConfirmUpdateModal}>No</Button>
                    <Button onClick={submitForm}>Yes</Button>
                </div>
            </Modal>

        </div>
    );
}

export default EditKeypad;