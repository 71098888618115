import { FunctionComponent, useEffect, useRef } from "react";

import styles from "./keypadListItem.module.css"
import { RightOutlined } from "@ant-design/icons";
import Container from "../Container/container";

interface KeypadListItemProps {
    keypad: any,
    setActiveKeypad: any
}

const KeypadListItem: FunctionComponent<KeypadListItemProps> = ({ keypad, setActiveKeypad }) => {
    const optionsRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        let element = optionsRef.current as Element

        let observer = new ResizeObserver((elements) => {

            elements.forEach(element => {
                setTimeout(() => {
                    // @ts-ignore
                    element.target.style.width = element.contentRect.height + "px"

                }, 0);
            })
        })

        observer.observe(element)

        return () => {
            observer.unobserve(element)
        }
    }, [optionsRef])

    return (
        <Container style={{ padding: "10px" }} >
            <div className={styles.keypadListItem}>
                <div className={styles.keypadInfo}>
                    <p className={styles.name}>
                        {keypad.keypadCustomName}
                    </p>
                    <p className={styles.comment}>
                        {keypad.keypadComment}
                    </p>

                </div>
                <div className={styles.options}>

                    <div className={styles.option} ref={optionsRef}
                        onClick={setActiveKeypad}
                    >
                        <RightOutlined />
                    </div>
                </div>
            </div>

        </Container>
    );
}

export default KeypadListItem;