import { FunctionComponent } from "react";

import styles from "./eye.module.css";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

interface EyeProps {
    open: boolean,
    onClick: Function
}

const Eye: FunctionComponent<EyeProps> = ({ open, onClick }) => {

    return (
        <div className={styles.eyeContainer} onClick={() => onClick()}>
            <div className={styles.eye} >
                {open
                    ? <EyeOutlined />
                    : <EyeInvisibleOutlined />
                }
            </div>
        </div >
    )
}

export default Eye;