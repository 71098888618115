import { FunctionComponent, LabelHTMLAttributes, ReactNode } from "react";

import styles from "./formElement.module.css";
interface FormElementProps {
    text?: string
    errorMessage?: string
    children: ReactNode
}

const FormElement: FunctionComponent<FormElementProps & LabelHTMLAttributes<{}>> = ({ text, errorMessage, children, ...props }) => {

    return (
        <div className={styles.formElementContainer}>
            {text
                ? <label {...props} style={{ fontSize: "1em" }}>{text}</label>
                : <></>
            }
            <div className={styles.formElement}>
                {children}
            </div>
            <div className={[styles.errorMessageContainer, errorMessage === undefined ? "" : styles.show].join(" ")}>
                <p className={styles.errorMessage}>
                    {errorMessage}
                </p>
            </div>
        </div>
    );
}

export default FormElement;