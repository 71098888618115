import { FunctionComponent, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Container from "../../components/Container/container";
import FormElement from "../../components/FormElement/formElement";
import Button from "../../components/Button/button";
import Eye from "../../components/Eye/eye";
import UserActions from "../../api/actions/user.actions";

interface ResetPasswordProps {

}

type ResetPasswordFormValues = {
    password: string
    email: string
}

const ResetPassword: FunctionComponent<ResetPasswordProps> = () => {
    const [searchParams] = useSearchParams()

    const [showPassword, setShowPassword] = useState(false)
    const [loading, setLoading] = useState(false)
    const [validationErrors, setValidationErrors] = useState(new Map())
    const [errors, setErrors] = useState()
    const [successMessage, setSuccessMessage] = useState()
    const resetToken = useRef(searchParams.get("token"))

    async function submitHandler(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault()

        if (!resetToken.current) return

        setLoading(true);
        setValidationErrors(new Map())
        setErrors(undefined)
        setSuccessMessage(undefined)

        const formData = new FormData(e.target as HTMLFormElement);
        const data: ResetPasswordFormValues = {
            email: formData.get("email") as string,
            password: formData.get("password") as string
        };

        const { success, message, validationErrors } = await UserActions.resetPassword({ ...data, token: resetToken.current })

        if (!success) {
            if (validationErrors) {
                setValidationErrors(() => {
                    let errors = new Map()
                    validationErrors.forEach((err: any) => {
                        let errorMessage = Object.values(err.constraints)[0];
                        errors.set(err.property, errorMessage)

                    });

                    return errors;
                })

            }
            setErrors(message)

        } else {
            setSuccessMessage(message)
        }

        setLoading(false);
    }

    return (
        <div>
            <h1>Reset password</h1>
            <Container>
                <form onSubmit={submitHandler}>
                    <fieldset>

                        <FormElement text="Email" htmlFor="email" errorMessage={validationErrors.get("email")}>
                            <input type="email" id="email" name="email" autoComplete="email" />
                        </FormElement>

                        <FormElement text="New password" htmlFor="password" errorMessage={validationErrors.get("password")}>
                            <input type={showPassword ? "text" : "password"} id="password" name="password" autoComplete="new-password" minLength={6} />
                            <Eye open={showPassword} onClick={() => setShowPassword(prev => !prev)} />
                        </FormElement>

                        <FormElement>
                            <Button block loading={loading}>Reset</Button>
                        </FormElement>
                    </fieldset>
                </form>

                {errors &&
                    <Container isError>
                        <h3>{errors}</h3>
                    </Container>
                }

                {successMessage && (
                    <Container isSuccess>
                        <h3>{successMessage}</h3>
                    </Container>
                )}
            </Container >
        </div>
    );
}

export default ResetPassword;
