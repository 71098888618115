import Icon, { HomeOutlined, LeftOutlined, MenuOutlined } from "@ant-design/icons";
import { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router";
import Drawer from "../Drawer/drawer";
import { NavLink } from "react-router-dom";

import { ReactComponent as device } from "../../assets/images/KeypadInvertv2.svg";

import styles from "./navigation.module.css"

interface NavigationProps {
    title: string
}

const Navigation: FunctionComponent<NavigationProps> = ({ title }) => {
    const [optionsOpen, setOptionsOpen] = useState(false)

    const navigate = useNavigate();

    const logOut = () => {
        localStorage.clear()
        navigate("/login")
    }

    return (
        <>
            <div className={styles.headerContainer}>

                <header className={styles.header}>
                    <h2 className={styles.title}>{title}</h2>
                    <MenuOutlined className={styles.openNavigation} onClick={() => setOptionsOpen(true)} />

                </header>
            </div>

            <Drawer isOpen={optionsOpen} showCloseButton close={() => setOptionsOpen(false)}>
                <nav className={styles.nav}>
                    <div className={styles.links}>
                        <NavLink to={"/"} className={({ isActive }) =>
                            [
                                isActive ? styles.active : "",
                                styles.link
                            ].join(" ")
                        }>
                            <HomeOutlined />
                            <p>Home</p>
                        </NavLink>

                        <NavLink to={"/keypads"} className={({ isActive }) =>
                            [
                                isActive ? styles.active : "",
                                styles.link
                            ].join(" ")
                        }>
                            <Icon component={device} />
                            <p>Keypads</p>
                        </NavLink>

                    </div>

                    <NavLink to="/login" onClick={logOut} className={({ isActive }) =>
                        [
                            isActive ? styles.active : "",
                            styles.link
                        ].join(" ")} >
                        <LeftOutlined />
                        <p>Log out</p>
                    </NavLink>

                    {/* <p onClick={() => navigate("/")}>Home</p>
                    <p onClick={() => navigate("/keypads")}>Keypads</p>
                    <p onClick={logOut} >Log out</p> */}
                </nav>
            </Drawer>
        </>
    );
}

export default Navigation;