import { FunctionComponent, ReactNode, useEffect, useState } from "react";

import styles from "./modal.module.css"

interface ModalProps {
    isOpen: boolean
    close: Function
    header?: ReactNode
    children: ReactNode
}

const Modal: FunctionComponent<ModalProps> = ({ isOpen, close, header, children }) => {

    return (
        <div className={[styles.backdrop, isOpen ? styles.open : "",].join(" ")} onClick={() => close()}>
            <div className={styles.modalContent} onClick={(e) => { e.stopPropagation() }}>
                {header ?
                    <div className={styles.header}>
                        {header}
                    </div>
                    : <></>
                }
                {children}
            </div>

        </div>
    );
}

export default Modal;