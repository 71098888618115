import CodeService from "../services/code.service";

interface DefaultRequest {
    success: boolean
    message: string
    statusCode: number
}

interface GenerateCodeRequest extends DefaultRequest {
    code: string,
    address: string,
    validationErrors: Object[]
}

export interface GenerateBody {
    userSeed: string
    typeOfCode: string
    position: number
    location: {
        latitude: string
        longitude: string
    }
}

export default class CodeActions {

    static async generate(codeInfo: GenerateBody): Promise<GenerateCodeRequest> {
        let date = new Date();

        let info = {
            ...codeInfo,
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            day: date.getDate(),
            hour: date.getHours()
        }

        return await CodeService.generate(info)
    }
}
