import { FunctionComponent, useEffect, useRef, useState } from "react";
import FormElement from "../../components/FormElement/formElement";
import Button from "../../components/Button/button";
import CodeActions from "../../api/actions/code.actions";
import { useNavigate } from "react-router";
import Container from "../../components/Container/container";
import KeypadActions from "../../api/actions/keypad.actions";
import Navigation from "../../components/Navigation/navigation";

import styles from "./codeGenerator.module.css";

interface CodeGeneratorProps {
    token?: any
}

type GenerateCodeFormValues = {
    typeOfCode: string
}

type RegisterFormValues = {
    keypadCustomName: string,
    keypadServiceConfCode: string,
    keypadUserConfCode: string
}

const CodeGenerator: FunctionComponent<CodeGeneratorProps> = ({ token }) => {
    const [location, setLocation] = useState<GeolocationCoordinates>();
    const [locationError, setLocationError] = useState<string>()
    const [generatedCode, setGeneratedCode] = useState<string>()
    const [address, setAddress] = useState<string>()
    const [generateCodeError, setGenerateCodeError] = useState<{ message: string, statusCode: number }>()
    const [registerKeypadValidationErrors, setRegisterKeypadValidationErrors] = useState(new Map())
    const [registerKeypadErrors, setRegisterKeypadErrors] = useState<{ message: string, statusCode: number }>()
    const [seedValidationErrors, setSeedValidationErrors] = useState(new Map())
    const [registerLoading, setRegisterLoading] = useState(false)
    const [registerKeypadSuccessMessage, setRegisterKeypadSuccessMessage] = useState<string>()
    const [loading, setLoading] = useState(false)

    const seedRef = useRef<HTMLInputElement>(null)
    const geolocationWatcher = useRef<number>()
    const oneTimeCodesGenerated = useRef<{ seed: string, codesGenerated: number }>({ seed: "", codesGenerated: 0 })

    const navigate = useNavigate()

    useEffect(() => {
        if (!token) navigate("/login")

        geolocationWatcher.current = navigator.geolocation.watchPosition(
            (e) => { setLocation(e.coords) },
            (e) => { setLocationError(e.message) })

        return () => {
            if (geolocationWatcher.current)
                navigator.geolocation.clearWatch(geolocationWatcher.current)
        }

    }, []) // eslint-disable-line

    async function generateCode(e: any) {
        e.preventDefault();

        let seedValid = seedRef.current?.reportValidity()
        if (!seedValid || !seedRef.current) return;

        const seed = seedRef.current.value;

        setLoading(true);
        setGenerateCodeError(undefined)
        setSeedValidationErrors(new Map())

        const data = Object.fromEntries(new FormData(e.target as HTMLFormElement).entries()) as GenerateCodeFormValues;

        if (seed !== oneTimeCodesGenerated.current.seed) {
            oneTimeCodesGenerated.current.seed = seed
            oneTimeCodesGenerated.current.codesGenerated = 0
        }

        if (location) {

            const _location = {
                latitude: location.latitude.toString(),
                longitude: location.longitude.toString()
            }

            let { code, message, success, statusCode, address: _address, validationErrors } = await CodeActions.generate({ typeOfCode: data.typeOfCode, userSeed: seed, position: oneTimeCodesGenerated.current.codesGenerated, location: _location });

            if (success) {
                setGeneratedCode(code);
                setAddress(_address)


                if (data.typeOfCode === "oneTimeCode") {
                    oneTimeCodesGenerated.current.codesGenerated += 1
                }
            }
            else if (validationErrors) {
                validationErrors.forEach((err: any) => {

                    let errorMessage = Object.values(err.constraints)[0];

                    if (err.property === "userSeed") {
                        let seedErrors = new Map()
                        seedErrors.set("userSeed", errorMessage)
                        setSeedValidationErrors(seedErrors)
                    }
                });

            } else if (message) {
                setGenerateCodeError({ message, statusCode })
            }
        }

        setLoading(false);
    }

    const register = async (e: any) => {
        e.preventDefault()

        setRegisterLoading(true)
        setSeedValidationErrors(new Map())
        setRegisterKeypadSuccessMessage(undefined)
        setRegisterKeypadErrors(undefined)
        setRegisterKeypadValidationErrors(new Map())

        let seedValid = seedRef.current?.reportValidity()
        if (!seedValid || !seedRef.current) return;

        const seed = seedRef.current.value;

        let values = Object.fromEntries(new FormData(e.target as HTMLFormElement)) as RegisterFormValues

        if (location) {
            const _location = {
                latitude: location.latitude.toString(),
                longitude: location.longitude.toString()
            }

            const { message, success, validationErrors, statusCode } = await KeypadActions.register({ location: _location, userSeed: seed, ...values })

            if (!success) {
                if (validationErrors) {
                    let errors = new Map()

                    validationErrors.forEach((err: any) => {

                        let errorMessage = Object.values(err.constraints)[0];

                        if (err.property === "userSeed") {
                            let seedErrors = new Map()
                            seedErrors.set("userSeed", errorMessage)
                            setSeedValidationErrors(seedErrors)
                        } else {
                            errors.set(err.property, errorMessage)
                        }
                    });
                    setRegisterKeypadValidationErrors(errors)

                }
                if (message) {
                    setRegisterKeypadErrors({ message, statusCode });
                }
            } else {
                setRegisterKeypadSuccessMessage(message)
            }

        }
        setRegisterLoading(false)
    }

    return (
        <div className={styles.codeGenerator}>
            <Navigation title="Home" />

            {locationError &&
                <Container isError style={{ margin: "20px" }}>
                    <h3>{locationError}</h3>
                </Container>
            }
            <Container>

                <FormElement text="Seed code" htmlFor="seed" errorMessage={seedValidationErrors.get("userSeed")} >
                    <input inputMode="numeric" name="seed" id="seed" required minLength={8} maxLength={8} ref={seedRef} />
                </FormElement>
            </Container>
            <Container>
                <form className={styles.form} onSubmit={register}>
                    <fieldset disabled={locationError !== undefined || registerLoading}>
                        <FormElement text="Locker name" htmlFor="keypadCustomName" errorMessage={registerKeypadValidationErrors.get("keypadCustomName")}>
                            <input name="keypadCustomName" id="keypadCustomName" required />
                        </FormElement>

                        <FormElement text="Service Config Code" htmlFor="keypadServiceConfCode" errorMessage={registerKeypadValidationErrors.get("keypadServiceConfCode")}>
                            <input inputMode="numeric" name="keypadServiceConfCode" id="keypadServiceConfCode" required minLength={8} maxLength={8} />
                        </FormElement>

                        <FormElement text="User Config Code" htmlFor="keypadUserConfCode" errorMessage={registerKeypadValidationErrors.get("keypadUserConfCode")}>
                            <input inputMode="numeric" name="keypadUserConfCode" id="keypadUserConfCode" required minLength={4} maxLength={8} />
                        </FormElement>

                        <FormElement>
                            <Button loading={registerLoading} block >Register</Button>
                        </FormElement>
                    </fieldset>
                </form>

                {
                    registerKeypadSuccessMessage &&
                    <Container isSuccess >
                        <h3>{registerKeypadSuccessMessage}</h3>
                    </Container>
                }

                {registerKeypadErrors &&
                    <Container isError>
                        <h3> {registerKeypadErrors.message} </h3>
                        {registerKeypadErrors.statusCode === 401 &&
                            <Button onClick={() => navigate("/login")} block style={{ marginTop: "20px" }}>
                                Login
                            </Button>
                        }
                    </Container>
                }
            </Container>

            <Container>
                <form className={styles.form} onSubmit={generateCode}>
                    <fieldset disabled={locationError !== undefined || loading}>
                        <FormElement text="Code type">

                            <div className={styles.radioButtonContainer}>
                                <input type="radio" name="typeOfCode" value="dayCode" id="dayCode" required />
                                <label htmlFor="dayCode">Day code</label>
                            </div>

                            <div className={styles.radioButtonContainer}>
                                <input type="radio" name="typeOfCode" value="oneTimeCode" id="oneTimeCode" required />
                                <label htmlFor="oneTimeCode">One time code</label>
                            </div>

                            <div className={styles.radioButtonContainer}>
                                <input type="radio" name="typeOfCode" value="hourCode" id="hourCode" required />
                                <label htmlFor="hourCode">Hour code</label>
                            </div>
                        </FormElement>

                        <FormElement>
                            <Button type="submit" loading={loading} block>Generate</Button>
                        </FormElement>
                    </fieldset>
                </form>

                {generateCodeError &&
                    <Container isError>
                        <h3>{generateCodeError.message}</h3>
                        {generateCodeError.statusCode === 401 &&
                            <Button onClick={() => navigate("/login")} block style={{ marginTop: "20px" }}>
                                Login
                            </Button>
                        }
                    </Container>
                }
            </Container>


            {generatedCode &&
                <Container>
                    <h2 className={styles.code}>{generatedCode}</h2>
                    <h3 className={styles.address}>{address}</h3>
                </Container>
            }

        </div>
    );
}

export default CodeGenerator;