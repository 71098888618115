import { FunctionComponent, useEffect, useState } from 'react';
import './App.css';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import CodeGenerator from './pages/CodeGenerator/codeGenerator';
import Login from './pages/Login/login';
import Register from './pages/Register/register';
import KeypadList from './pages/KeypadList/keypadList';
import ForgotPassword from './pages/ForgotPassword/forgotPassword';
import ResetPassword from './pages/ResetPassword/resetPassword';

interface AppProps {

}

const App: FunctionComponent<AppProps> = () => {
  const [token, setToken] = useState<string | null>(localStorage.getItem("token"))

  useEffect(() => {
    if (token) localStorage.setItem("token", token)
  }, [token])


  const router = createBrowserRouter([
    {
      path: "/login",
      element: <Login setToken={setToken} />,
    },
    {
      path: "/register",
      element: <Register setToken={setToken} />,
    },
    {
      path: "/forgot-password",
      element: <ForgotPassword />,
    },
    {
      path: "/reset-password",
      element: <ResetPassword />,
    },
    {
      path: "",
      children: [
        {
          path: "/",
          element: 
          <CodeGenerator token={token} />,
        },
        {
          path: "/keypads",
          element: <KeypadList token={token} />,
        },
      ]
    },
    {
      path: "*",
      element: <Navigate to="/" />
    },
  ]);

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
