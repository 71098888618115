import UserService from "../services/user.service";

interface DefaultResponse {
    success: boolean,
    message: string
}

interface RegisterResponse extends DefaultResponse {
    validationErrors?: Object[]
    token: string
}

interface LoginResponse extends DefaultResponse {
    validationErrors?: Object[]
    token: string
}

interface ForgotPasswordResponse extends DefaultResponse {
    validationErrors?: Object[]
}

export interface RegisterBody {
    email: string
    name: string
    company: string
    phone: string
    password: string
}

export interface LoginBody {
    email: string
    password: string
}

export interface ForgotPasswordBody {
    email: string
}

export interface ResetPasswordBody {
    token: string
    password: string
    email: string
}

export default class UserActions {

    static async register(userInfo: RegisterBody): Promise<RegisterResponse> {
        return await UserService.register(userInfo)
    }

    static async login(userInfo: LoginBody): Promise<LoginResponse> {
        return await UserService.login(userInfo)
    }

    static async forgotPassword(email: string): Promise<ForgotPasswordResponse> {
        return await UserService.forgotPassword(email)
    }

    static async resetPassword(body: ResetPasswordBody) {
        return await UserService.resetPassword(body)
    }
}
