import { handleResponse } from "../helpers/responseHandler";
import { LoginBody, RegisterBody, ResetPasswordBody } from "../actions/user.actions"

export default class UserService {

    private static url = (
        process.env.NODE_ENV === "development"
            ? process.env.REACT_APP_API_URL_DEV
            : process.env.REACT_APP_API_URL_PRODUCTION
    ) + "/user";

    static async register(body: RegisterBody) {
        const requestOptions: RequestInit = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body)
        }

        return fetch(`${this.url}/register`, requestOptions).then(handleResponse);
    }

    static async login(body: LoginBody) {
        const requestOptions: RequestInit = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body)
        }

        return fetch(`${this.url}/login`, requestOptions).then(handleResponse);
    }

    static async forgotPassword(email: string) {
        const requestOptions: RequestInit = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email })
        }

        return fetch(`${this.url}/forgot-password`, requestOptions).then(handleResponse);

    }

    static async resetPassword(body: ResetPasswordBody) {
        const requestOptions: RequestInit = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body)
        }

        return fetch(`${this.url}/reset-password`, requestOptions).then(handleResponse);

    }

}
