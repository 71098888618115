import { FunctionComponent, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import FormElement from "../../components/FormElement/formElement";
import Button from "../../components/Button/button";
import Eye from "../../components/Eye/eye";
import UserActions from "../../api/actions/user.actions";

import styles from "./login.module.css";
import Container from "../../components/Container/container";

interface LoginProps {
    setToken: React.Dispatch<React.SetStateAction<string | null>>
}

type LoginFormValues = {
    email: string
    password: string
}

const Login: FunctionComponent<LoginProps> = ({ setToken }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [loginErrors, setLoginErrors] = useState<string>()
    const [loginValidationErrors, setLoginValidationErrors] = useState(new Map())
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate();

    async function submitHandler(e: any) {
        e.preventDefault()

        setLoading(true);
        setLoginValidationErrors(new Map())
        setLoginErrors(undefined)

        const data = Object.fromEntries(new FormData(e.target).entries()) as LoginFormValues;

        const { success, message, validationErrors, token } = await UserActions.login(data)

        if (!success) {
            if (validationErrors) {
                setLoginValidationErrors(() => {
                    let errors = new Map()
                    validationErrors.forEach((err: any) => {
                        let errorMessage = Object.values(err.constraints)[0];
                        errors.set(err.property, errorMessage)

                    });

                    return errors;
                })

            }
            setLoginErrors(message)

        } else {
            setToken(token);
            navigate("/")
        }

        setLoading(false);
    }

    return (
        <div className={styles.login}>
            <h1>Login</h1>

            <Container>
                <form onSubmit={submitHandler} >
                    <fieldset disabled={loading}>
                        <FormElement text="Email" htmlFor="email" errorMessage={loginValidationErrors.get("email")}>
                            <input name="email" type="email" />
                        </FormElement>

                        <FormElement text="Password" htmlFor="password" errorMessage={loginValidationErrors.get("password")}>
                            <input name="password" type={showPassword ? "text" : "password"} />
                            <Eye open={showPassword} onClick={() => setShowPassword(prev => !prev)} />
                        </FormElement>

                        <FormElement>
                            <Button type="submit" loading={loading} block>Login</Button>
                        </FormElement>

                    </fieldset>
                </form>

                {loginErrors &&
                    <Container isError>
                        <h3>{loginErrors}</h3>
                    </Container>
                }
            </Container>
            <p>Forgot password? <Link to="/forgot-password">Reset</Link></p>
            <p className={styles.registerLink}>Don't have an account? <Link to="/register">Register</Link></p>
        </div>
    );
}

export default Login;