import { FunctionComponent, HTMLAttributes } from "react";
import styles from "./container.module.css"

interface ContainerProps {
    children: any,
    isError?: boolean
    isSuccess?: boolean
}

const Container: FunctionComponent<ContainerProps & HTMLAttributes<HTMLDivElement>> = ({ children, isError, isSuccess, ...props }) => {
    return (
        <div className={[
            styles.container,
            isError ? styles.error : "",
            isSuccess ? styles.success : "",

        ].join(" ")} {...props}
        >
            {children}
        </div>
    );
}

export default Container;